import {useDispatch, useSelector} from "react-redux";
import {
    getAssetId,
    getAssetIdFrom,
    getIsSwap,
    setAssetId,
    setAssetIdFrom,
} from "../../../ducks/add-transaction-duck.js";
import {getFilterPreset} from "../../../ducks/assets-duck.js";
import './StepSelectAsset.scss'
import ArrowDownIcon from "mdi-react/ArrowDownIcon.js";
import {scrollToDashBoard} from "../../util/navigation/navigation.js";
import AssetSelector from "../../util/asset-selector/AssetSelector.jsx";

export default () => {
    const dispatch = useDispatch()

    const assetId = useSelector(getAssetId)
    const assetIdFrom = useSelector(getAssetIdFrom)
    const filterPreset = useSelector(getFilterPreset)
    const isSwap = useSelector(getIsSwap)

    const selectAssetId = assetId => {
        dispatch(setAssetId(assetId))
        scrollToDashBoard()
    }
    const selectAssetIdFrom = assetId => {
        dispatch(setAssetIdFrom(assetId))
        scrollToDashBoard()
    }

    if (isSwap) {
        return (
            <div className='step-select-asset'>
                <AssetSelector
                    headerSelect='Select asset swapping from'
                    headerSelected='From asset'
                    selectedAssetId={assetIdFrom}
                    selectAsset={selectAssetIdFrom}
                    filterPreset={filterPreset}
                />
                <div className='center-arrow'>
                    <ArrowDownIcon/>
                </div>
                <AssetSelector
                    headerSelect='Select asset swapping to'
                    headerSelected='To asset'
                    selectedAssetId={assetId}
                    selectAsset={selectAssetId}
                    filterPreset={filterPreset}
                />
            </div>
        )
    }

    return (
        <div className='step-select-asset'>
            <AssetSelector
                selectedAssetId={assetId}
                selectAsset={selectAssetId}
                filterPreset={filterPreset}
            />
        </div>
    )
}