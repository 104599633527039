import Card from "../../util/Card.jsx";
import Table from "../../util/table/Table.jsx";
import Counter from "../../util/Counter.jsx";
import {useSummary} from "./_summary.js";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import ArrowRightThickIcon from "mdi-react/ArrowRightThickIcon.js";
import {getDirectionColorClassName} from "../../../util/misc.js";
import {useSelector} from "react-redux";
import {getIsSwap} from "../../../ducks/add-transaction-duck.js";
import './StepDetailsSummary.scss'

export default () => {

    const isSwap = useSelector(getIsSwap)

    const {
        valuesMainAsset,
        valuesSecondaryAsset,
    } = useSummary()

    return (
        <Card className='step-details-summary'>
            <Table>
                <tbody>
                {isSwap && <AssetRows {...valuesSecondaryAsset} />}
                <AssetRows {...valuesMainAsset} />
                </tbody>
            </Table>
        </Card>
    )
}

const AssetRows = ({
                       name,
                       effectiveRate,
                       newCost,
                       oldCost,
                       newQuantity,
                       oldQuantity,
                       quantityChangePercent,
                       quantityCostPercent,
                   }) => {
    return (
        <>
            <tr>
                <td colSpan={3} className='asset-header'>
                    {name}
                </td>
            </tr>
            <tr>
                <td>
                    Amount
                </td>
                <td className='numeric flex-vertical-center'>
                    <Counter
                        amount={oldQuantity}
                        decimals={DECIMAL_FORMAT.FLEX}
                        noColor={true}
                        speed={0}
                    />
                    <ArrowRightThickIcon/>
                    <Counter
                        amount={newQuantity}
                        decimals={DECIMAL_FORMAT.FLEX}
                        noColor={true}
                        speed={0}
                    />
                </td>
                <td>
                    <Counter
                        amount={quantityChangePercent * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        noColor={true}
                        absolute={true}
                        className={getDirectionColorClassName(quantityChangePercent)}
                        prefix={quantityChangePercent < 0 ? '-' : '+'}
                        suffix={'%'}
                        speed={0}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    Cost
                </td>
                <td className='numeric flex-vertical-center'>
                    <Counter
                        amount={oldCost}
                        decimals={DECIMAL_FORMAT.FLEX}
                        noColor={true}
                        absolute={true}
                        className={oldCost < 0 ? 'is-golden' : ''}
                        speed={0}
                    />
                    <ArrowRightThickIcon/>
                    <Counter
                        amount={newCost}
                        decimals={DECIMAL_FORMAT.FLEX}
                        noColor={true}
                        absolute={true}
                        className={newCost < 0 ? 'is-golden' : ''}
                        speed={0}
                    />
                </td>
                <td>
                    <Counter
                        amount={quantityCostPercent * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        noColor={true}
                        absolute={true}
                        className={getDirectionColorClassName(quantityCostPercent)}
                        prefix={quantityCostPercent < 0 ? '-' : '+'}
                        suffix={'%'}
                        speed={0}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    Effective rate
                </td>
                <td className='numeric'>
                    <Counter
                        amount={effectiveRate}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        noColor={true}
                        speed={0}
                    />
                </td>
                <td/>
            </tr>
        </>
    )
}