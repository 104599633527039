import './AssetList.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getIsAssetListCompositionBarVisible,
    getSortIsAsc,
    getSortProp,
    setDateFilter,
    setSortIsAsc,
    setSortProp
} from "../../ducks/assets-duck.js";
import {APP_ROUTES} from "../../App.jsx";
import Table from "../util/table/Table.jsx";
import ThSortToggler from "../util/table/ThSortToggler.jsx";
import {getUpdateKey} from "../../ducks/chart-duck.js";
import InternalLink from "../util/navigation/InternalLink.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import AssetListCompositionBar from "./AssetListCompositionBar.jsx";
import AssetRow from "./AssetRow.jsx";
import {useAssets} from "./use-assets.js";
import Card from "../util/Card.jsx";
import CloseIcon from "mdi-react/CloseIcon.js";
import dayjs from "dayjs";
import {numberFormat} from "../../util/numbers.js";
import DirectionIcon from "../util/icons/DirectionIcon.jsx";
import {useAssetCharts} from "./use-asset-charts.js";

const TH = ({children, sortProp, sortProps, className, isNumeric}) => {

    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)

    return (
        <ThSortToggler
            className={className}
            sortProp={sortProp}
            sortProps={sortProps}
            currentSortProp={currentSortProp}
            currentSortIsAsc={currentSortIsAsc}
            toggleSortAction={setSortProp}
            toggleIsAscAction={setSortIsAsc}
            isNumeric={isNumeric}
        >
            {children}
        </ThSortToggler>
    )
}

export default () => {

    const dispatch = useDispatch()

    const {
        assetsOrdered,
        isAllAssetListGrouped,
        dateFilter,
    } = useAssets()

    useAssetCharts()

    const updateKey = useSelector(getUpdateKey)
    const isAssetListCompositionBarVisible = useSelector(getIsAssetListCompositionBarVisible)

    const secondsSinceFilterDate = dayjs().diff(dayjs(dateFilter))
    const daysSinceFilterDate = Math.floor(secondsSinceFilterDate / (60 * 60 * 24 * 1000))

    const gainChange = assetsOrdered.reduce((acc, cur) => acc + (cur.value - (cur.value / (1 + cur.changePct))), 0)

    return (
        <div className='asset-list' key={updateKey}>
            <div className='palette-test'>
                <div className='p3'>p3</div>
                <div className='p2'>p2</div>
                <div className='p1'>p1</div>
                <div className='n1'>n1</div>
                <div className='n2'>n2</div>
                <div className='n3'>n3</div>
            </div>
            {dateFilter &&
            <Card
                className='date-filter'
                onClick={() => dispatch(setDateFilter())}
            >
                <div className='flex-vertical-center'>
                    <div className='desc'>
                        Portfolio date
                    </div>
                    <div className='days-since'>
                        {daysSinceFilterDate}
                    </div>
                    <div className='date'>
                        {dateFilter}
                    </div>
                    <div className='direction'>
                        <DirectionIcon value={gainChange}/>
                    </div>
                    <div className={`change`}>
                        {numberFormat(Math.abs(gainChange))}
                    </div>
                    <div className='close-icon'>
                        <CloseIcon/>
                    </div>
                </div>
            </Card>
            }
            {assetsOrdered.length > 0 &&
            <>
                {isAssetListCompositionBarVisible && <AssetListCompositionBar/>}
                <Table>
                    <thead>
                    <tr>
                        <th className='hide-portrait'/>
                        <TH
                            sortProps={['name']}
                        >
                            {isAllAssetListGrouped ? 'Group' : 'Name'}
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['currentRate']}
                        >
                            {isAllAssetListGrouped ? '' : 'Rate'}
                        </TH>
                        <td className='recent-history'/>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['value']}
                        >
                            Value
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['gain', 'gainPct']}
                        >
                            Return
                        </TH>
                        <TH
                            isNumeric={true}
                            sortProps={['change', 'changePct', 'changePctAbs']}
                        >
                            Change
                        </TH>
                        <th className='hide-portrait'/>
                    </tr>
                    </thead>
                    <tbody className='children-slide-right'>
                    {assetsOrdered.map(asset =>
                        <AssetRow
                            key={asset.id}
                            {...asset}
                        />
                    )}
                    </tbody>
                    {assetsOrdered.length > 0 &&
                    <tfoot>
                    <tr className='hide-portrait'>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td className='numeric dull'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td>
                        </td>
                    </tr>
                    </tfoot>
                    }
                </Table>
            </>
            }
            {assetsOrdered.length === 0 &&
            <div className='options-bar'>
                <InternalLink
                    to={APP_ROUTES.transactionsAdd}
                    isButton={true}
                    icon={<PlusIcon/>}
                >
                    add asset
                </InternalLink>
            </div>
            }
        </div>
    )
}