export const THRESHOLDS = {
    POSITIVE_L3: .1,
    POSITIVE_L2: .05,
    NEGATIVE_l2: -.05,
    NEGATIVE_l3: -.1,
}

export const ATH_THRESHOLD = .985 // ath above 98.5%

export const isRateAth = (currentRate, maxRate) =>
    currentRate > maxRate * ATH_THRESHOLD