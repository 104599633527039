import dayjs from "dayjs";

const ANNOTATIONS = [
    {
        date: '2024-11-05',
        content: 'US election'
    },
]

export const getAnnotations = ({
                                   labels,
                                   palette,
                                   dateFilter,
                               }) => {

    const isAnnotationSetReduced = labels.length > 400

    const {
        annotationGradient = 'rgba(255,255,255, 15%)',
    } = palette

    const labelAnnotations = getAnnotationsFromLabels(labels, isAnnotationSetReduced)

    const minDate = labels[labels.length - 1]

    const annotations = isAnnotationSetReduced ?
        labelAnnotations :
        [
            ...labelAnnotations,
            ...ANNOTATIONS.filter(annotation => dayjs(annotation.date) >= dayjs(minDate) && dayjs(annotation.date) <= dayjs()),
        ]

    let annotationOptions = annotations.reduce((acc, cur) => ({
        ...acc,
        [cur.date]: {
            type: 'line',
            xMin: cur.date,
            xMax: cur.date,
            borderColor: annotationGradient,
            borderWidth: 2,
            // borderDash: [15,5],
            label: {
                content: cur.content,
                display: true,
                position: 'end',
                backgroundColor: 'rgba(0,0,0,75%)',
            }
        }
    }), {})

    // annotationOptions.test = {
    //     type: 'label',
    //     // xValue: 2.5,
    //     xValue: '2024-12-06',
    //     xAdjust: 290,
    //     yAdjust: -100,
    //     backgroundColor: 'rgba(245,245,245)',
    //     content: ['This is my text', 'This is my text, second line'],
    //     textAlign: 'start',
    //     font: {
    //         size: 18
    //     },
    //     callout: {
    //         display: true,
    //         side: 10
    //     }
    // }

    if (dateFilter) {
        // overwrite other annotations
        annotationOptions = {
            dateFilter: {
                type: 'line',
                xMin: dateFilter,
                xMax: dateFilter,
                borderColor: annotationGradient,
                borderWidth: 2,
                // borderDash: [15,5],
                label: {
                    content: dateFilter,
                    display: true,
                    position: 'end',
                    backgroundColor: 'rgba(0,0,0,75%)',
                }
            }
        }
    }

    return annotationOptions

}

const getAnnotationsFromLabels = (labels, isAnnotationSetReduced) => {
    const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "mayu",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
    ]
    return labels.map(label => {
        const [y, m, d] = label.split('-')
        if (m === '01' && d === '01') {
            return {
                date: label,
                content: y,
            }
        }
        if (d === '01' && !isAnnotationSetReduced && false) {
            return {
                date: label,
                content: months[parseInt(m) - 1],
            }
        }
        return null
    }).filter(i => i)
}