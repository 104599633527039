import Button from "../../util/Button.jsx";
import {CURRENCIES} from "../../../../../back-end/constants/currencies.js";
import './CurrencyPicker.scss'
import {useDispatch} from "react-redux";
import {setCurrency} from "../../../ducks/add-transaction-duck.js";
import {closePopup} from "../../../ducks/popup-duck.js";

export default () => {
    return (
        <div className='currency-picker'>
            <h2>
                Pick Currency
            </h2>
            <div className='currencies flex-vertical-center'>
                {CURRENCIES.map(currency =>
                    <Currency
                        key={currency.name}
                        {...currency}
                    />
                )}
            </div>
        </div>
    )
}

const Currency = ({name, symbol}) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(setCurrency(name))
        dispatch(closePopup())
    }
    return (
        <Button className='currency' onClick={onClick}>
            {name} <span className='symbol'>{symbol}</span>
        </Button>
    )
}