import {isMobile} from "../../util/misc.js";
import {getRelativePosition} from "chart.js/helpers";
import {getAnnotations} from "./_annotation.js";

export const getOptions = ({
                               isZeroBaseValue = false,
                               onMouseLeave = () => {
                               },
                               onMouseEnter = () => {
                               },
                               onMouseMove = dataIndex => console.log(dataIndex),
                               onClick = (dataX, dataY) => console.log({dataX, dataY}),
                               showDates = !isMobile(),
                               chart = null,
                               palette,
                               labels = [],
                               dateFilter,
                           }) => {

    const annotations = getAnnotations({
        labels,
        palette,
        dateFilter,
    })

    return {
        // maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        elements: {
            line: {
                tension: 1
            },
        },
        onClick: e => {
            if (chart) {
                const canvasPosition = getRelativePosition(e, chart.current)
                const dataX = chart.current?.scales?.x.getValueForPixel(canvasPosition.x)
                const dataY = chart.current?.scales?.y.getValueForPixel(canvasPosition.y)
                onClick(dataX, dataY)
            }
        },
        events: ['mouseenter', 'mouseleave', 'mouseout', 'mousedown', 'mouseup', 'mousemove'],
        plugins: {
            eventPlugin: {
                events: ['mouseenter', 'mouseleave'],
                onMouseLeave,
                onMouseEnter,
                onClick,
            },
            tooltip: {
                enabled: false,
                external: function (context) {
                    const {dataIndex} = context.tooltip?.dataPoints[0]
                    onMouseMove(dataIndex)
                }
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations,
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    round: 'date',
                    displayFormats: {
                        month: 'MMMM YYYY',
                        // hour: 'MM-DD HH:ii'
                    },
                },
                border: {
                    display: showDates,
                },
                ticks: {
                    display: showDates,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: isZeroBaseValue,
                border: {
                    display: !isMobile(),
                },
                ticks: {
                    display: !isMobile(),
                },
                grid: {
                    display: false,
                },
            }
        }
    }
}