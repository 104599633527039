import {assetTypeToGroup, FILTER_PRESET_NAMES} from "../../ducks/assets-duck.js";
import dayjs from "dayjs";
import {ASSET_IDS} from "../../util/asset-ids.js";
import {isRateAth} from "../../../../back-end/constants/thresholds.js";

export const getIsAssetDollarBased = props => {
    let {id, is_usd_asset, currentRateUsd} = props
    if (id === ASSET_IDS.DAI) {
        return !(1.005 > currentRateUsd > 0.995)
    }
    return is_usd_asset
}

export const getIsAth = props => {
    const {is_usd_asset, currentRateUsd, max_rate_usd, currentRate, max_rate} = props
    return is_usd_asset ?
        isRateAth(currentRateUsd, max_rate_usd)
        :
        isRateAth(currentRate, max_rate)
}

export const groupAssetsByType = (assetList = []) => {
    const totalValue = assetList.reduce((acc, cur) => cur.value + acc, 0)
    const groups = assetList.reduce((acc, cur) => {
        const {
            id,
            asset_type,
            gain,
            value,
            changePct,
            isMostRecentUpdate,
            currentRateDate,
        } = cur
        const group = assetTypeToGroup(asset_type)
        if (!acc[group]) {
            acc[group] = {
                // static
                id: group,
                name: FILTER_PRESET_NAMES[group],
                code: group,
                isGroup: true,
                // aggregates
                value: 0,
                prevValue: 0,
                changePct: 0,
                gain: 0,
                gainPct: 0,
                superGainPct: 0,
                currentRateDate: undefined,
                isMostRecentUpdate: false,
                totalValue: 0,
                // irrelevants
                currentRate: 0,
                currentRateUsd: 0,
                rateAth: 0,
                rateUsdAth: 0,
                is_usd_asset: false,
                largestValue: 0,
                largestValueId: null,
            }
        }
        const gainNew = acc[group].gain + gain
        const valueNew = acc[group].value + value

        const isLaterDate = !!acc[group].currentRateDate && dayjs(currentRateDate) > dayjs(acc[group].currentRateDate)
        const isOlderDate = !!acc[group].currentRateDate && dayjs(currentRateDate) < dayjs(acc[group].currentRateDate)
        if (isLaterDate) {
            acc[group].prevValue = acc[group].value
        }
        let prevValue = value
        if (!isOlderDate) {
            prevValue = value / (1 + changePct)
        }
        const prevValueNew = acc[group].prevValue + prevValue
        return {
            ...acc,
            [group]: {
                ...acc[group],
                isMostRecentUpdate: acc[group].isMostRecentUpdate || isMostRecentUpdate,
                value: valueNew,
                gain: gainNew,
                gainPct: gainNew / (valueNew - gainNew),
                superGainPct: gainNew > valueNew ? gainNew / valueNew : 0,
                prevValue: prevValueNew,
                changePct: (valueNew - prevValueNew) / prevValueNew,
                currentRateDate: isLaterDate || !acc[group].currentRateDate ? currentRateDate : acc[group].currentRateDate,
                ratio: valueNew / totalValue,
                largestValue: value > acc[group].largestValue ? value : acc[group].largestValue,
                largestValueId: value > acc[group].largestValue ? id : acc[group].largestValueId,
            }
        }
    }, {})

    return Object.values(groups)

}