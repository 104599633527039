import StepDetailsSummary from "./StepDetailsSummary.jsx";
import './StepConfirmation.scss'
import {useDispatch, useSelector} from "react-redux";
import {getComment, setComment} from "../../../ducks/add-transaction-duck.js";

export default () => {
    const dispatch = useDispatch()
    const comment = useSelector(getComment)
    return (
        <div className='step-confirmation'>
            <StepDetailsSummary />
            <div className='inputs'>
                <textarea
                    placeholder={'Comment'}
                    value={comment}
                    onChange={e=>dispatch(setComment(e.target.value))}
                />
            </div>
        </div>
    )
}