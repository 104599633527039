import {useDispatch, useSelector} from "react-redux";
import Button from "../util/Button.jsx";
import {activateWidePopup} from "../../ducks/popup-duck.js";
import AddRateDialog from "./AddRateDialog.jsx";
import {getIsOddy} from "../../ducks/user-duck.js";
import {getCurrentAsset} from "../../ducks/assets-duck.js";
import TagEditOutlineIcon from "mdi-react/TagEditOutlineIcon.js";
import TagPlusOutlineIcon from "mdi-react/TagPlusOutlineIcon.js";

export default ({date, rate, dateHasRate}) => {

    const dispatch = useDispatch()
    const isOddy = useSelector(getIsOddy)
    const {id, currentRate, name} = useSelector(getCurrentAsset)

    const onClick = e => {
        e.stopPropagation()
        dispatch(activateWidePopup(
            <AddRateDialog
                date={date}
                currentRate={rate || currentRate}
                assetId={id}
                assetName={name}
            />
        ))
    }

    return isOddy && (
        <Button
            className='add-rate-button'
            icon={dateHasRate ? <TagEditOutlineIcon className='stale'/> : <TagPlusOutlineIcon />}
            onClick={onClick}
            isIconOnly={true}
        />
    )
}