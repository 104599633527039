import {
    getAssetId,
    getAssetIdFrom, getCurrency,
    getIsCompanyTransaction,
    getIsDividend,
    getIsGas,
    getIsSale,
    getIsSwap,
    getRawCost,
    getRawFromQuantity,
    getRawQuantity,
    getTransactionDate,
    setCost,
    setQuantity,
    setQuantityFrom,
    setTransactionDate,
    toggleIsCompanyTransaction
} from "../../../ducks/add-transaction-duck.js";
import {useDispatch, useSelector} from "react-redux";
import './StepDetails.scss'
import Button from "../../util/Button.jsx";
import RobotOutlineIcon from "mdi-react/RobotOutlineIcon.js";
import {getSpecificAsset} from "../../../ducks/assets-duck.js";
import {getTodayString} from "../../../util/time-and-date.js";
import StepDetailsSummary from "./StepDetailsSummary.jsx";
import {useSummary} from "./_summary.js";
import Toggle from "../../util/Toggle.jsx";
import BriefcaseAccountOutlineIcon from "mdi-react/BriefcaseAccountOutlineIcon.js";
import {CURRENCIES} from "../../../../../back-end/constants/currencies.js";
import {activatePopup} from "../../../ducks/popup-duck.js";
import CurrencyPicker from "./CurrencyPicker.jsx";
import {useState} from "react";
import DatabaseOutlineIcon from "mdi-react/DatabaseOutlineIcon.js";

export default () => {

    const dispatch = useDispatch()

    const date = useSelector(getTransactionDate)
    const quantity = useSelector(getRawQuantity)
    const quantityFrom = useSelector(getRawFromQuantity)
    const isCompanyTransaction = useSelector(getIsCompanyTransaction)
    const cost = useSelector(getRawCost)
    const isSale = useSelector(getIsSale)
    const isSwap = useSelector(getIsSwap)
    const isGas = useSelector(getIsGas)
    const isDividend = useSelector(getIsDividend)
    const currency = useSelector(getCurrency)

    const assetId = useSelector(getAssetId)
    const {code} = useSelector(state => getSpecificAsset(state, assetId))

    const assetIdFrom = useSelector(getAssetIdFrom)
    const {code: codeFrom} = useSelector(state => getSpecificAsset(state, assetIdFrom))

    const [isQuantityDividend, setIsQuantityDividend] = useState(quantity > 0)

    const currencySymbol = CURRENCIES.find(item => item.name === currency)?.symbol || '??'

    const {
        valuesMainAsset,
        valuesSecondaryAsset,
    } = useSummary()

    const currentRate = valuesMainAsset.currentRate
    const currentRateFrom = valuesSecondaryAsset.currentRate

    const autoCalcCost = () => {
        const newCost = quantity ? currentRate * (quantity * 1) : currentRateFrom * (quantityFrom * 1)
        dispatch(setCost(newCost))
    }

    const autoCalcQuantity = () => {
        const newQuantity = isSale || isGas ? valuesMainAsset.oldQuantity : (cost * 1) / currentRate
        dispatch(setQuantity(newQuantity))
    }

    const autoCalcQuantityFrom = () => {
        dispatch(setQuantityFrom(valuesSecondaryAsset.oldQuantity))
        dispatch(setQuantity(currentRateFrom / currentRate * valuesSecondaryAsset.oldQuantity))
    }

    const setDateToToday = () =>
        dispatch(setTransactionDate(getTodayString()))

    const openCurrencyPicker = () =>
        dispatch(activatePopup(<CurrencyPicker />))

    return (
        <div className='step-details'>
            <StepDetailsSummary/>
            <div className='inputs'>
                <div className='input-container'>
                    <label>
                        Transaction date
                    </label>
                    <div className='content'>
                        <Button
                            icon={<RobotOutlineIcon/>}
                            onClick={setDateToToday}
                            max={getTodayString()}
                        >
                            Today
                        </Button>
                        <input
                            className='is-button-connected'
                            type="date"
                            value={date}
                            onChange={e => dispatch(setTransactionDate(e.target.value))}
                        />
                    </div>
                </div>
                {isSwap &&
                <div className='input-container'>
                    <label>
                        Quantity from
                    </label>
                    <div className='content'>
                        <Button
                            icon={<RobotOutlineIcon/>}
                            onClick={autoCalcQuantityFrom}
                        >
                            100%
                        </Button>
                        <input
                            className='is-button-connected'
                            type="number"
                            value={quantityFrom}
                            onChange={e => dispatch(setQuantityFrom(e.target.value))}
                        />
                        <div className='denominator'>
                            {codeFrom}
                        </div>
                    </div>
                </div>
                }
                {(!isDividend || isQuantityDividend) &&
                <div className='input-container'>
                    <label>
                        {isSwap ? 'Quantity to' : 'Quantity'}
                    </label>
                    <div className='content'>
                        <Button
                            icon={<RobotOutlineIcon/>}
                            disabled={!cost && !isSale && !isGas}
                            onClick={autoCalcQuantity}
                        >
                            {isSale || isGas ? '100%' : 'Auto'}
                        </Button>
                        <input
                            className='is-button-connected'
                            type="number"
                            value={quantity}
                            onChange={e => dispatch(setQuantity(e.target.value))}
                        />
                        <div className='denominator'>
                            {code}
                        </div>
                    </div>
                </div>
                }
                {(!isGas && !isQuantityDividend) &&
                <div className='input-container'>
                    <label>
                        {isSale || isDividend ? 'Proceeds' : 'Cost'}
                    </label>
                    <div className='content'>
                        <Button
                            icon={<RobotOutlineIcon/>}
                            disabled={!quantity && !quantityFrom}
                            onClick={autoCalcCost}
                        >
                            Auto
                        </Button>
                        <input
                            className='is-button-connected'
                            type="number"
                            value={cost}
                            onChange={e => dispatch(setCost(e.target.value))}
                        />
                        <div className='denominator clickable' onClick={openCurrencyPicker}>
                            {currencySymbol}
                        </div>
                    </div>
                </div>
                }
                {isDividend &&
                <div className='input-container'>
                    <Toggle
                        isChecked={isQuantityDividend}
                        onChange={() => setIsQuantityDividend(!isQuantityDividend)}
                    >
                        <div className='flex-vertical-center'>
                            <DatabaseOutlineIcon/>
                            Use quantity (airdrop, rewards, etc.)
                        </div>
                    </Toggle>
                </div>
                }
                <div className='input-container'>
                    <Toggle
                        isChecked={isCompanyTransaction}
                        onChange={() => dispatch(toggleIsCompanyTransaction())}
                    >
                        <div className='flex-vertical-center'>
                            <BriefcaseAccountOutlineIcon/>
                            Is company transaction
                        </div>
                    </Toggle>
                </div>
            </div>
        </div>
    )
}