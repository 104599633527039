import Button from "../util/Button.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getTransactionFilter, toggleTransactionFilter} from "../../ducks/transactions-duck.js";
import CloseIcon from "mdi-react/CloseIcon.js";
import './FilterToggleButton.scss'

export default ({
                    icon,
                    prop = 'filterBurn',
                }) => {

    const dispatch = useDispatch()
    const value = useSelector(state => getTransactionFilter(state, prop))

    return (
        <Button
            icon={icon}
            onClick={() => dispatch(toggleTransactionFilter(prop))}
            className={`filter-toggle-button v-${value}`}
        >
            {value === -1 && <CloseIcon className='close-icon'/>}
        </Button>
    )
}