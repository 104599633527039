import {useSelector} from "react-redux";
import {getComparativeAssetCode, getComparativeAssetRate, getDateFilter} from "../../ducks/assets-duck.js";
import {getSubPathCache} from "../../ducks/user-duck.js";
import {APP_ROUTES} from "../../App.jsx";
import {scrollToTop, useNavigation} from "../util/navigation/navigation.js";
import {getDirectionColorClassName} from "../../util/misc.js";
import dayjs from "dayjs";
import ChangeFlavorIcon from "../util/icons/ChangeFlavorIcon.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import Counter from "../util/Counter.jsx";
import DirectionIcon from "../util/icons/DirectionIcon.jsx";
import AssetIcon from "../util/icons/AssetIcon.jsx";
import {ASSET_CHART_DATA} from "./use-asset-charts.js";
import AssetRowRate from "./AssetRowRate.jsx";
import {getIsAth} from "./_helpers.js";

export default props => {

    const {
        id,
        name,
        code,
        value,
        currentRateDate,
        changePct,
        gain,
        gainPct,
        superGainPct,
        isMostRecentUpdate,
        largestValueId,
        isGroup = false,
    } = props

    const comparativeAssetRate = useSelector(getComparativeAssetRate)
    const comparativeAssetCode = useSelector(getComparativeAssetCode)
    const dateFilter = useSelector(getDateFilter)
    const subPathCache = useSelector(getSubPathCache)

    const prevVale = value / (1 + changePct)
    const latestGainChange = value - prevVale
    const isAth = getIsAth(props)

    let route = subPathCache ? APP_ROUTES.history.replace('history', subPathCache) : APP_ROUTES.history
    if (isGroup) {
        route = APP_ROUTES.filter.replace(':filterId', id)
    }
    const {navigateTo} = useNavigation(scrollToTop)


    const secondsSinceRate = dayjs().diff(dayjs(currentRateDate))
    const daysSinceRate = Math.floor(secondsSinceRate / (60 * 60 * 24 * 1000))

    const isMultiColored = daysSinceRate <= 5
    const changeClassName = getDirectionColorClassName(changePct, isMultiColored)

    return (
        <tr onClick={() => navigateTo(route, {filterId: id})}>
            <td className='fade-left hide-portrait'/>
            <td className='asset-name'>
                <div className='flex-vertical-center'>
                    {isGroup &&
                    <AssetIcon
                        assetType={id}
                        className='asset-icon std-icon'
                    />
                    }
                    <span className='name'>
                        {name}
                    </span>
                    {isMultiColored &&
                    <ChangeFlavorIcon amount={changePct}/>
                    }
                    <span className='code'>
                        {!isGroup && code}
                    </span>
                </div>
                <div className='small hide-desktop'>
                    <AssetRowRate
                        daysSinceRate={daysSinceRate}
                        {...props}
                    />
                </div>
            </td>
            <td className='numeric hide-portrait rate'>
                <AssetRowRate
                    daysSinceRate={daysSinceRate}
                    {...props}
                />
            </td>
            <td className='recent-history numeric'>
                {ASSET_CHART_DATA[largestValueId || id] && !dateFilter &&
                <img
                    src={ASSET_CHART_DATA[largestValueId || id]}
                    alt='recent history'
                    className={`${!isMostRecentUpdate ? 'is-old' : ''} ${changeClassName} ${isAth ? 'is-ath' : ''}`}
                />
                }
            </td>
            <td className='value numeric hide-portrait'>
                <Counter
                    amount={value}
                />
                <div className='small dull comp-rate'>
                    <Counter
                        prefix={comparativeAssetCode}
                        amount={value / comparativeAssetRate}
                    />
                </div>
            </td>
            <td className={`numeric hide-portrait`}>
                <DirectionIcon value={gain}/>
                <Counter
                    amount={gain}
                    absolute={true}
                />
                {(superGainPct || gainPct) && Math.abs(gainPct) !== Infinity &&
                <div
                    className={`small ${gainPct > 0 ? 'is-positive' : 'is-negative'} ${superGainPct > 0 ? 'is-golden' : ''}`}>
                    <Counter
                        amount={(superGainPct || gainPct) * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
                }
            </td>
            <td className={`change numeric ${!isMostRecentUpdate && 'old-rate'}`}>
                <DirectionIcon
                    value={changePct}
                    isMultiColored={isMultiColored}
                />
                <Counter
                    amount={latestGainChange}
                    decimals={0}
                    absolute={true}
                />
                <div className={`small ${changeClassName}`}>
                    <Counter
                        amount={changePct * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </td>
            <td className='fade-right hide-portrait'/>
        </tr>
    )
}