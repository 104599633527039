import './AssetKPIs.scss'
import {useSelector} from "react-redux";
import {
    FILTER_KEY_CRYPTO, getAssetBitcoin,
    getAssetSelectionAggregate,
    getAssetSol,
    getFilterPreset,
    getIsSingleAsset,
    getRethRate,
    getUsdRate
} from "../../ducks/assets-duck.js";
import Counter from "../util/Counter.jsx";
import Card from "../util/Card.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import GainPct from "../util/GainPct.jsx";
import {getIsOddy} from "../../ducks/user-duck.js";
import WaterOutlineIcon from "mdi-react/WaterOutlineIcon.js";
import HorizontalLineIcon from "mdi-react/HorizontalLineIcon.js";

export default () => {

    const usdRate = useSelector(getUsdRate)
    const isSingleAsset = useSelector(getIsSingleAsset)
    const isOddy = useSelector(getIsOddy)
    const filterPreset = useSelector(getFilterPreset)

    const showReBalanceCalc = !isSingleAsset && isOddy && filterPreset === FILTER_KEY_CRYPTO

    const {
        value,
        cost,
        gain,
        days_age,
        weighted_days_age,
    } = useSelector(getAssetSelectionAggregate)

    const weightedDaysAge = weighted_days_age / value
    const gainsAnnualized = (gain / Math.min(Math.abs(weightedDaysAge), Math.abs(days_age))) * 365

    return (
        <>
            <div className='asset-kpis children-slide-right s10'>
                <KPI
                    header={'Returns annualized'}
                    className='returns-annualized'
                >
                    <WaterOutlineIcon className='is-golden'/>
                    {cost === 0 ?
                        <HorizontalLineIcon style={{opacity: '100%'}}/> :
                        <>
                            <Counter
                                amount={gainsAnnualized}
                                decimals={DECIMAL_FORMAT.FLEX_DEC}
                                suffix={''}
                                absolute={true}
                            />
                            <GainPct
                                className='secondary'
                                value={value + gainsAnnualized}
                                gain={gainsAnnualized}
                                decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                            />
                        </>
                    }
                </KPI>
                <KPI
                    header={'Total value'}
                    className='total-value'
                >
                    <Counter
                        amount={value}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                    />
                    <Counter
                        amount={value / usdRate}
                        className='secondary weak'
                        prefix={`$`}
                        decimals={DECIMAL_FORMAT.FLEX}
                    />
                </KPI>
                <KPI
                    header={cost >= 0 ? 'Net cost' : 'Realized gains'}
                    className={cost > 0 ? '' : 'golden'}
                >
                    <Counter
                        amount={cost}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        absolute={true}
                    />
                    {Math.abs(cost) > .1 &&
                    <Counter
                        amount={cost / usdRate}
                        className='secondary weak'
                        prefix={`$`}
                        decimals={DECIMAL_FORMAT.FLEX}
                        absolute={true}
                    />
                    }
                </KPI>
                {showReBalanceCalc ?
                    <ReBalanceCalculationKPI totalValue={value}/>
                    :
                    <KPI
                        header={gain < 0 ? 'Loss' : 'Return'}
                        className='return'
                    >
                        <Counter
                            amount={gain}
                            decimals={DECIMAL_FORMAT.FLEX_DEC}
                            absolute={true}
                        />
                        <GainPct
                            className='secondary'
                            decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                            value={value}
                            gain={gain}
                        />
                    </KPI>
                }
            </div>
        </>
    )
}

const ReBalanceCalculationKPI = ({totalValue}) => {
    const rethRate = useSelector(getRethRate)
    const isOddy = useSelector(getIsOddy)
    const filterPreset = useSelector(getFilterPreset)
    const assetBitcoin = useSelector(getAssetBitcoin)
    const assetSolana = useSelector(getAssetSol)

    const TARGET_BASE = 10_000_000
    const target = TARGET_BASE + assetBitcoin.value

    const solTarget = TARGET_BASE * .1
    const targetThreshold = .05

    const targetDiff = target - totalValue
    const targetDiffReth = targetDiff / rethRate
    const isDiffAboveThreshold = Math.abs(targetDiff) > TARGET_BASE * targetThreshold

    const solTargetDiff = solTarget - assetSolana.value
    const solTargetDiffSol = solTargetDiff / assetSolana.currentRate
    const isSolDiffAboveThreshold = Math.abs(solTargetDiff) > solTarget * targetThreshold

    return isOddy && FILTER_KEY_CRYPTO === filterPreset && (
        <KPI>
            <div className='recalc'>
                <div className={`
                    flex-vertical-center
                    recalc-line
                    ${targetDiff < 0 && isDiffAboveThreshold && 'is-positive'}
                    ${targetDiff > 0 && isDiffAboveThreshold && 'is-negative'}
                `}>
                    <div className='secondary weak'>
                        {targetDiff > 0 ? 'BUY' : 'SELL'}
                    </div>
                    <Counter
                        amount={targetDiffReth}
                        decimals={2}
                        suffix={' rETH'}
                        absolute={true}
                    />
                    <Counter
                        className='weak'
                        amount={targetDiff}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        absolute={true}
                    />
                    <Counter
                        className='small weak'
                        amount={targetDiff / TARGET_BASE * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
                <div className={`
                    flex-vertical-center
                    recalc-line
                    ${solTargetDiff < 0 && isSolDiffAboveThreshold && 'is-positive'}
                    ${solTargetDiff > 0 && isSolDiffAboveThreshold && 'is-negative'}
                `}>
                    <div className='secondary weak'>
                        {solTargetDiff > 0 ? 'BUY' : 'SELL'}
                    </div>
                    <Counter
                        amount={solTargetDiffSol}
                        decimals={2}
                        suffix={' SOL'}
                        absolute={true}
                    />
                    <Counter
                        className=' weak'
                        amount={solTargetDiff}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        absolute={true}
                    />
                    <Counter
                        className='small weak'
                        amount={solTargetDiff / solTarget * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </div>

        </KPI>
    )
}

const KPI = ({
                 header = '',
                 children,
                 isVisible = true,
                 className = "",
                 isPositive,
                 isNegative,
             }) => {
    if (!isVisible) {
        return null
    }
    return (
        <Card
            className={`kpi ${className}`}
            isPositive={isPositive}
            isNegative={isNegative}
        >
            {!!header &&
            <h3>
                {header}
            </h3>
            }
            <div className='kpi-value'>
                {children}
            </div>
        </Card>
    )
}