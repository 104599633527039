import {DECIMAL_FORMAT} from "../../util/numbers.js";
import Counter from "../util/Counter.jsx";
import dayjs from "dayjs";
import './AllTimeHighBarometer.scss'
import CountDownChart2 from "./CountDownChart2.jsx";

export default ({
                    currentValue = 0,
                    athValue = 0,
                    initialValue = 0,
                    rateChange,
                    rateDate,
                    athDate = dayjs().format('YYYY-MM-DD'),
                    decimals = (DECIMAL_FORMAT.FLEX_DEC),
                    children,
                }) => {

    let change = initialValue === 0 ? 1 : currentValue / initialValue
    let changeClass = change < 0 ? 'negative' : 'positive'
    if(initialValue <= 0){
        changeClass = 'golden'
        change = currentValue / (initialValue + currentValue)
    }

    const changePct = change * 100
    const changePadding = Math.floor(Math.log(Math.abs(changePct)) / 2)
    let rateClass = rateChange > 0 ? 'positive' : 'negative'
    if(rateChange === 0){
        rateClass = 'is-golden'
    }


    return (
        <div className={`ath-barometer ${!!initialValue ? 'has-change' : ''}`} style={{ "--padding": `${changePadding}rem` }}>
            <div className='text'>
                <h3>
                    {children}
                </h3>
                <div className='value'>
                    <Counter
                        amount={athValue}
                        decimals={decimals}
                        className='current-value'
                        absolute={true}
                    />
                    {!!initialValue &&
                        <div className={`change-value ${changeClass}`}>
                            <Counter
                                amount={changePct}
                                decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                                suffix='%'
                                absolute={true}
                            />
                        </div>
                    }
                    {(!!rateChange || rateChange === 0) &&
                        <div className={`change-value ${rateClass}`}>
                            <Counter
                                prefix={'+'}
                                amount={rateChange}
                                decimals={DECIMAL_FORMAT.FLEX}
                                absolute={true}
                            />
                        </div>
                    }
                </div>
                <div className='ath-date'>
                    <span className='prefix'>on</span> {athDate}
                </div>
            </div>
            <div className='graphic'>
                <CountDownChart2
                    amount={currentValue}
                    targetAmount={athValue}
                />
            </div>
        </div>
    )
}