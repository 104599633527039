import DecagramIcon from "mdi-react/DecagramIcon.js";
import './AthBadge.scss'

export default () => {
    return (
        <div className='ath-badge'>
            <DecagramIcon />
            <div className='text'>
                ATH
            </div>
        </div>
    )
}