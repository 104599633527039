import {useDispatch, useSelector} from "react-redux";
import {closePopup} from "../../ducks/popup-duck.js";
import {useState} from "react";
import Button from "../util/Button.jsx";
import './AssetSplitDialog.scss'
import AddIcon from "mdi-react/AddIcon.js";
import {postAssetSplit} from "../../api/statusApi.js";
import {getCurrentAsset} from "../../ducks/assets-duck.js";
import Fraction from "fraction.js";
import {setup} from "../setup/_setup.js";

export default () => {

    const dispatch = useDispatch()
    const [isPosting, setIsPosting] = useState(false)
    const [dateInput, setInputDate] = useState()
    const [quantity, setQuantity] = useState()

    const {
        id: assetId,
        name: assetName,
        amount: currentQuantity,
    } = useSelector(getCurrentAsset)

    const isInputValid = quantity && quantity !== currentQuantity && dateInput

    const postRate = async () => {
        setIsPosting(true)
        await postAssetSplit(assetId, {
            date: dateInput,
            quantityNew: quantity,
        })
        await setup(dispatch, true)
        dispatch(closePopup())
    }

    const onChangeNewQuantityInput = e => {
        setQuantity(e.target.value)
    }

    const fraction = Fraction(currentQuantity).div(quantity || 1)
    const [from, to] = fraction.simplify(0.05).toFraction()?.split('/')

    return (
        <div className='asset-split-dialog'>
            <h2>
                Split or merge <span className='asset-name'>{assetName}</span>
            </h2>
            <div className='input-div'>
                <input
                    type='date'
                    value={dateInput}
                    onChange={e => setInputDate(e.target.value)}
                />
            </div>
            <div className='input-div'>
                <input
                    type='number'
                    value={quantity || currentQuantity}
                    placeholder={'new quantity'}
                    onChange={onChangeNewQuantityInput}
                />
                <div className='label'>
                    new quantity
                </div>
            </div>
            <div className='summary flex-vertical-center'>
                <div className='from'>
                    {to ? from : 1}
                </div>
                <div className='divider'>
                    :
                </div>
                <div className='to'>
                    {to || '?'}
                </div>
            </div>
            <div className='button-container'>
                <Button
                    onClick={postRate}
                    disabled={isPosting || !isInputValid}
                    icon={<AddIcon/>}
                >
                    {quantity > currentQuantity ? 'Split' : 'Merge'}
                </Button>
            </div>
        </div>
    )
}