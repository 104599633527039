export const API_ROUTE_ROOT = ''
export const API_ROUTE_AUTHENTICATION = `${API_ROUTE_ROOT}/auth`

export const API_ROUTE_TEST = `${API_ROUTE_ROOT}/test`

export const API_ROUTE_STATUS = `${API_ROUTE_ROOT}/status`

export const API_ROUTE_RATES = `${API_ROUTE_ROOT}/rates`
export const API_ROUTE_PLANS = `${API_ROUTE_ROOT}/plans`
export const API_ROUTE_RATES_LIMITED = `${API_ROUTE_RATES}/limited`

export const API_ROUTE_ASSETS = `${API_ROUTE_ROOT}/assets`
export const API_ROUTE_ASSETS_LINK = `${API_ROUTE_ASSETS}/link`
export const API_ROUTE_ASSETS_CHARTS = `${API_ROUTE_ASSETS}/charts`
export const API_ROUTE_ASSETS_SPLIT = `${API_ROUTE_ASSETS}/:assetId/split`

export const API_ROUTE_CURRENCY_RATE = `${API_ROUTE_ROOT}/currencies/:code/rate`
export const API_ROUTE_CURRENCY_RATE_FOR_DATE = `${API_ROUTE_ROOT}/currencies/:code/rate/:date`

export const API_ROUTE_RATES_FOR_DATE = `${API_ROUTE_RATES}/date/:date`

export const API_ROUTE_TRANSACTIONS = `${API_ROUTE_ROOT}/transactions`
export const API_ROUTE_TRANSACTIONS_BULK = `${API_ROUTE_ROOT}/transactions/bulk`
export const API_ROUTE_TRANSACTION = `${API_ROUTE_ROOT}/transactions/:transactionId`

export const API_ROUTE_DAWG = `${API_ROUTE_ROOT}/dawg`
export const API_ROUTE_DAWG_LOG = `${API_ROUTE_DAWG}/log`

export const API_ROUTE_CRYPTO = `${API_ROUTE_ROOT}/crypto`
export const API_ROUTE_CRYPTO_BALANCE = `${API_ROUTE_CRYPTO}/balance`

export const API_ROUTE_DASHBOARD = `${API_ROUTE_ROOT}/dashboard`

export const getApiRoute = (route, params = {}) =>
    Object.keys(params).reduce((acc, cur) => {
        return acc.replace(`:${cur}`, params[cur])
    }, route)